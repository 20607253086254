<template>
  <div>
    <b-row v-if="balanceHistory.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="balanceHistory"
            :fields="field"
            @row-dblclicked="test"
            head-row-variant="secondary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(status)="data">
              <span v-if="data.item.status == 'SUCCESS'"> Успешно </span>
              <span v-else> Неудачно </span>
            </template>
            <template #cell(created_at)="data">
              <span>
                {{
                  data.item.created_at.substring(
                    0,
                    data.item.created_at.indexOf("T")
                  ) +
                  " " +
                  data.item.created_at.substring(
                    data.item.created_at.indexOf("T") + 1,
                    data.item.created_at.indexOf(".")
                  )
                }}
              </span>
            </template>
            <template #cell(updated_at)="data">
              <span>
                {{
                  data.item.updated_at.substring(
                    0,
                    data.item.updated_at.indexOf("T")
                  ) +
                  " " +
                  data.item.updated_at.substring(
                    data.item.updated_at.indexOf("T") + 1,
                    data.item.updated_at.indexOf(".")
                  )
                }}
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
  data() {
    return {
      balanceHistory: [],
      field: [
        // {key: 'checkbox', label: '', thStyle: {width: '5px'}},
        { key: "id", label: "ID", thStyle: { width: "10px" } },
        {
          key: "promocode_usage_id",
          label: "Идентификатор",
          thStyle: { width: "50px" },
        },
        { key: "status", label: "Статус", thStyle: { width: "40px" } },
        { key: "amount", label: "Количество", thStyle: { width: "50px" } },
        { key: "sum_for", label: "Сумма за", thStyle: { width: "50px" } },
        { key: "sum_after", label: "Сумма после", thStyle: { width: "60px" } },
        { key: "order_id", label: "ID Заказа", thStyle: { width: "60px" } },
        { key: "user", label: "Пользователь", thStyle: { width: "70px" } },
        {
          key: "type_doc",
          label: "Тип документа",
          thStyle: { width: "100px" },
        },
        { key: "comment", label: "Коментарий", thStyle: { width: "40px" } },
        { key: "account_id", label: "ID аккаунта", thStyle: { width: "60px" } },
        { key: "created_at", label: "Создан", thStyle: { width: "90px" } },
        { key: "updated_at", label: "Изменен", thStyle: { width: "90px" } },
      ],
    };
  },
  methods: {
    test() {
      let division_id = JSON.parse(localStorage.getItem("userData"));
    },
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    resizeable();
    this.$http.get(`bonus-history/${this.$route.query.id}/`).then((res) => {
      this.balanceHistory = res.data;
      this.$store.commit("pageData/setdataCount", this.balanceHistory.length);
    });
  },
  watch: {
    "$route.query.filter": function (newPage, oldPage) {
      if (newPage !== oldPage) {
        this.$http.get(`bonus-history/${this.$route.query.id}/`).then((res) => {
          this.balanceHistory = res.data;
          this.$store.commit(
            "pageData/setdataCount",
            this.balanceHistory.length
          );
        });
      }
    },
  },
};
</script>
